import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.modalElement = document.getElementById(this.element.dataset.modalId || 'shared-modal');

    this.url = new URL(this.element.href || this.element.dataset.url);
    this.url.searchParams.append('layout', 'false');

    this.element.addEventListener("click", (event) => {
      this.show(event);
    });
  }

  show(event) {
    event.preventDefault();

    fetch(this.url).then(response => {
      if (!response.ok) {
        throw new Error('Network request for modal did not return ok');
      }
      return response.text();
    }).then(data => {
      this.modalElement.innerHTML = data;

      $(this.modalElement).modal('show')
      // const modal = bootstrap.Modal.getOrCreateInstance(this.modalElement);

      // modal.show();
    }).catch(error => {
      console.error('Error while fetching modal:', error);
    });
  }
}
